import { ADMIN_AVATAR, DEFAULT_AVATAR, TWILIO_ADMIN_IDENTITY } from '@gtintel/platform-constants';
import { RouteRolesEnum } from '@gtintel/platform-types';
const findUserFromMessage = (message, project, suggestedPartners = []) => {
  let messageUser = (project ? [project.client, project.partner, ...(project.members?.length ? project.members : [])] : []).filter(m => !!m).find(u => String(u.id) === String(message.author));
  if (!messageUser && Array.isArray(suggestedPartners)) {
    messageUser = suggestedPartners.filter(m => !!m).find(u => String(u.id) === String(message.author));
  }
  return messageUser;
};

// NB twilio API calls in this func may throw an error
export const getAllConversations = async client => {
  let page = await client.getSubscribedConversations();
  const conversations = page.items;
  while (page.hasNextPage) {
    page = await page.nextPage();
    conversations.push(...page.items);
  }

  // Remove duplicates
  return [...new Set(conversations)];
};
export const getMessageUserName = (message, user, project, suggestedPartners = []) => {
  const isAuthorAdmin = message.author === TWILIO_ADMIN_IDENTITY;
  const isCurrentUserAuthor = message.author === String(user.id);

  // Handle DMs
  if (!project) {
    return isCurrentUserAuthor ? 'You' : isAuthorAdmin ? 'GTI Staff' :
    // This _should_ never happen. If there is no project, the message _should_ be a DM but in order to preserve anonymity
    //  we should not be showing the author's name. This is a fallback in case something goes wrong.
    'Anonymous Client';
  }

  // 'You' are the author:

  // Either you wrote the message, or you're an admin and the author is an admin.
  if (isCurrentUserAuthor || isAuthorAdmin && user.is_administrator) {
    return 'You';
  }

  // 'You' are NOT the author:

  // Handle Admin messages that aren't DMs
  if (isAuthorAdmin) {
    return 'GTI Staff';
  }

  // All users on/related to a project.
  const allMembers = [...project.members, project.client, project.partner, ...suggestedPartners, ...(project.assignment_chats?.map(ac => ac.partner) ?? [])].filter(m => !!m);

  // Handle current user is admin.
  if (user.is_administrator) {
    // Look up the name of the author on the project and show that.
    const member = allMembers.find(m => String(m.id) === message.author);
    if (member) return `${member.last_name ? member.first_name + ' ' + member.last_name : member.first_name}`;
  }

  // Handle current user is client.
  if (user.is_client) {
    // Look up the name of the author on the project and show that - It's either another client on this project
    //  or the NP.
    const member = allMembers.find(m => String(m.id) === message.author);
    if (member) return `${member.last_name ? member.first_name + ' ' + member.last_name : member.first_name}`;
  }

  // Handle current user is NP.
  if (user.is_network_partner) {
    // TODO: This won't work for anonymous projects if we go down the route of allowing multiple partners on a project because
    //  NPs will see other NPs as 'Anonymous Client'.

    if (project?.request_anonymity ?? true) {
      return 'Anonymous Client';
    } else {
      // Look up the name of the author on the project and show that.
      const member = allMembers.find(m => String(m.id) === message.author);
      if (member) return `${member.last_name ? member.first_name + ' ' + member.last_name : member.first_name}`;
    }
  }

  // If we've reached this point, either something has gone wrong OR the user was removed from the project.
  return 'Removed User';
};
export const getMessageAuthorAvatar = (message, user, project, avatar = '') => {
  // Author is Admin
  if (message.author === TWILIO_ADMIN_IDENTITY) {
    return ADMIN_AVATAR;
  }

  // Handle DMs
  if (!project) return DEFAULT_AVATAR;

  // Handle anonymous projects
  if (user.is_network_partner && project.request_anonymity) {
    return DEFAULT_AVATAR;
  }
  return avatar ?? DEFAULT_AVATAR;
};
export const convertMessage = (message, project, loggedInUser, suggestedPartners = []) => {
  const messageUserName = getMessageUserName(message, loggedInUser, project, suggestedPartners);
  const messageUser = findUserFromMessage(message, project, suggestedPartners);
  return {
    authorAvatar: getMessageAuthorAvatar(message, loggedInUser, project, messageUser?.avatar),
    authorName: messageUserName,
    twilio: message
  };
};
export const getConversationName = ({
  conversation,
  routeRole,
  project
}) => {
  const convAttrs = conversation.attributes;
  if (routeRole === RouteRolesEnum.ADMIN) {
    let name = '';
    if (conversation.uniqueName.includes('client_partner')) {
      name = 'Project Chat (Client - Network Partner)';
    } else if (conversation.uniqueName.includes('client_admin')) {
      name = 'Client - GTI';
    } else if (conversation.uniqueName.includes('partner_admin')) {
      name = 'Partner - Admin';
    } else {
      name = convAttrs.project_name ?? '';
    }
    return name;
  }
  if (routeRole === RouteRolesEnum.CLIENT) {
    if (conversation.uniqueName?.includes('client_admin')) {
      return `GTI Staff`;
    }
    const partner = convAttrs.member?.find(m => m.is_partner);
    if (conversation.uniqueName?.includes('client_partner') && partner) {
      return `${partner.name} (Partner)`;
    }
    return `Partner`;
  }
  if (routeRole === RouteRolesEnum.PARTNER) {
    if (conversation.uniqueName.includes('partner_admin')) {
      return `GTI Staff (Admin)`;
    }
    if (project?.request_anonymity) {
      return `Anonymous Client`;
    }
    const user = convAttrs.member?.find(m => !m.is_partner && m.user_id !== TWILIO_ADMIN_IDENTITY);
    return user.name ? user.name : 'Client';
  }

  // This line should never be reached because routeRole should meet one of the three
  // conditions above... but just to be safe:
  return '';
};

// This isn't guaranteed to give the correct count - it may be an overestimate
// The accurate way would be to call conv.getUnreadMessagesCount() - but this
// requires us to make one call per conversation every time we want to update
// the unread message count (and for the 'total unread messages' icon in the
// navbar, this means: every time the current user receives a message.) This
// was causing huge amounts of calls to Twilio which made Twilio themselves
// email us and complain.
//
// However, the current approach will usually be accurate, and it's good enough.
export const countUnreadMessages = conversation => {
  // If there are no messages, then there are no unread messages!
  if (!conversation.lastMessage) return 0;
  const lastMessageIndex = conversation.lastMessage.index;
  const lastReadMessageIndex = conversation.lastReadMessageIndex;

  // plus one because they're indexed from zero:
  if (lastReadMessageIndex === null) return lastMessageIndex + 1;

  // The reason this may be an overestimate is because message indexes, while
  // always increasing, may not be consecutive (i.e. index X isn't necessarily
  // followed by index X+1, although the second index will always be bigger.)
  // In practice this happens very rarely - it's usually consecutive.
  return lastMessageIndex - lastReadMessageIndex;
};

// admin messages trigger a TASK_DONE graphql mutation, which must have a
// startIndex and endIndex. The indexes are used by the ML to determine the
// range of messages that the admin is replying to. The endIndex is the index
// of the admin message itself. The start message, calculated here, is the
// index of the *oldest* non-admin message that has no admin reply (other than
// the admin reply that was just sent.)
export const getStartIndex = messages => {
  if (messages.length === 0) {
    return null;
  }
  if (messages[messages.length - 1].twilio.author === TWILIO_ADMIN_IDENTITY) {
    return messages[messages.length - 1].twilio.index;
  }
  for (let i = messages.length - 2; i >= 0; i--) {
    if (messages[i].twilio.author === TWILIO_ADMIN_IDENTITY) {
      return messages[i + 1].twilio.index;
    }
  }
  return messages[0].twilio.index;
};
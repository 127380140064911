import styled from 'styled-components';
export const AuthorName = styled.span`
  font-weight: 600;
  margin-right: 8px;
`;
export const Body = styled.div`
  line-height: 21px;
  word-break: break-word;
  white-space: break-spaces;
  text-align: left;

  ${({
  isSent
}) => isSent ? `
      max-width: 440px;
    ` : `
      max-width: 571px;
      margin-bottom: 0;
    `}

  a {
    color: ${({
  isSent
}) => isSent ? '#4d7cfe' : '#252631'} !important;

    &:hover {
      text-decoration: underline;
    }

    .attach-icon {
      margin-right: 3px;
      vertical-align: middle;
    }
  }

  img {
    width: 33px;
    height: auto;
    max-height: 300px;
    object-fit: contain;
  }
`;
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  padding-top: 14px;

  .chat-message-user-avatar {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    position: relative;
    top: 3px;
  }
`;
export const InnerContainer = styled.div`
  flex-grow: 1;
  margin-right: 30px;
`;
export const Label = styled.div`
  border-radius: 16px;
  bottom: 1px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  margin-right: 7px;
  padding: 0.1rem 0.5rem;
  position: relative;

  // NB the colors are all from the Tailwind CSS default palette,
  // using the '100' and '700' versions of each color:
  // https://tailwindcss.com/docs/customizing-colors

  &.label-cardinal {
    // teal
    background-color: #ccfbf1;
    color: #0f766e;
  }

  &.label-gpe {
    // cyan
    background-color: #cffafe;
    color: #0e7490;
  }

  &.label-moment {
    // violet
    background-color: #ede9fe;
    color: #6d28d9;
  }

  &.label-money {
    // orange
    background-color: #ffedd5;
    color: #ea580c;
  }

  &.label-org {
    // pink
    background-color: #fce7f3;
    color: #be185d;
  }

  &.label-person {
    // lime
    background-color: #ecfccb;
    color: #4d7c0f;
  }

  &.label-urgent {
    // red
    background-color: #fee2e2;
    color: #b91c1c;
  }

  &:last-child {
    margin-right: 0;
  }
`;
export const Metadata = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 5px;
`;
export const NamedEntity = styled.span`
  color: rgb(255, 177, 107);
  font-weight: bold;
`;
export const Timestamp = styled.span`
  color: #6b7280;
`;